export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Home',
  },
  {
    title: 'Course Finder',
    route: 'course-finder',
    icon: 'FileIcon',
    action: 'read',
    resource: 'CourseFinder',
  },
  {
    title: 'Basic Schools',
    route: 'basic-school-db',
    icon: 'DatabaseIcon',
    action: 'read',
    resource: 'SchoolDB',
  },
  {
    title: 'Featured Schools',
    route: 'featured-school-db',
    icon: 'StarIcon',
    action: 'read',
    resource: 'CourseFinder',
  },
  {
    title: 'Exam Forms',
    route: 'exam-forms',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'ExamForms',
  },
  {
    title: 'School Images',
    route: 'school-images',
    icon: 'ImageIcon',
    action: 'read',
    resource: 'SchoolImages',
  },
]
